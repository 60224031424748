import axios from 'axios';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import SpinnerS from '../components/SpinnerS';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import BackTop from '../components/BackTop';

function Blogs() {
    const [postType, setPostType] = useState("");
    const [postList, setPostList] = useState([]);
    const getPostList = async () => {
        axios.get(`https://api.vtalentsolution.com/api/getPostsOn`)
        .then((res) => {
            setPostList(res.data);
        });
    }
    const catePost = postList.filter(item => item.type_post === postType);       
    useEffect(()=>{
            getPostList();
        },[])
    return (
    <>
        <Navbar/>
        {/* <!-- Header Start --> */}
        <div className="container-fluid page-header">
            <div className="container">
                <div className="d-flex flex-column justify-content-center" style={{minHeight: "150px"}}>
                    <h3 className="display-4 text-white text-uppercase">Blog</h3>
                    <div className="d-inline-flex text-white">
                        <p className="m-0 text-uppercase"><a className="text-white" href="/">Home</a></p>
                        <i className="fa fa-angle-double-right pt-1 px-3"></i>
                        <p className="m-0 text-uppercase">Blog</p>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Header End --> */}
         {/* <!-- Blog Start --> */}
        <div className="container-fluid">
            <div className="container py-5">
                <div className="row justify-content-center">
                    <div className="col-lg-12 mt-5 mt-lg-0">
                        <div className="row pb-3 justify-content-center gap-2">
                        {
                            postType == "" ?
                            <button type="button" className="btn btn-outline-primary mx-1 my-1 active">All</button>
                            :
                            <button type="button" className="btn btn-outline-primary mx-1 my-1" value="" onClick={(e)=>setPostType(e.target.value)}>All</button>
                        }
                        {
                            postType == "Journal" ?
                            <button type="button" className="btn btn-outline-primary mx-1 my-1 active">Journal</button>
                            :
                            <button type="button" className="btn btn-outline-primary mx-1 my-1" value="Journal" onClick={(e)=>setPostType(e.target.value)}>Journal</button>
                        }
                        {
                            postType == "Book" ?
                            <button type="button" className="btn btn-outline-primary mx-1 my-1 active">Book</button>
                            :
                            <button type="button" className="btn btn-outline-primary mx-1 my-1" value="Book" onClick={(e)=>setPostType(e.target.value)}>Book</button>
                        }
                        {
                            postType == "Proceeding" ?
                            <button type="button" className="btn btn-outline-primary mx-1 my-1 active">Proceeding</button>
                            :
                            <button type="button" className="btn btn-outline-primary mx-1 my-1" value="Proceeding" onClick={(e)=>setPostType(e.target.value)}>Proceeding</button>
                        }  
                        {
                            postType == "News" ?
                            <button type="button" className="btn btn-outline-primary mx-1 my-1 active">News</button>
                            :
                            <button type="button" className="btn btn-outline-primary mx-1 my-1" value="News" onClick={(e)=>setPostType(e.target.value)}>News</button>
                        }  
                        {
                            postType == "Event" ?
                            <button type="button" className="btn btn-outline-primary mx-1 my-1 active">Event</button>
                            :
                            <button type="button" className="btn btn-outline-primary mx-1 my-1" value="Event" onClick={(e)=>setPostType(e.target.value)}>Event</button>
                        }  
                        {
                            postType == "Review" ?
                            <button type="button" className="btn btn-outline-primary mx-1 my-1 active">Review</button>
                            :
                            <button type="button" className="btn btn-outline-primary mx-1 my-1" value="Review" onClick={(e)=>setPostType(e.target.value)}>Review</button>
                        }  
                        {
                            postType == "Link" ?
                            <button type="button" className="btn btn-outline-primary mx-1 my-1 active">Link</button>
                            :
                            <button type="button" className="btn btn-outline-primary mx-1 my-1" value="Link" onClick={(e)=>setPostType(e.target.value)}>Link</button>
                        }  
                        </div>
                        <hr className='border-dark border-5 border'/>
                        <div className="row pb-3 justify-content-start" style={{minHeight: "800px"}}>
                        {
                            postType == "" && postList && postList.length>0 && postList.map((item, index)=>(
                                <div className="col-md-8 col-lg-4 col-11 mb-4" key={index}>
                                    <div className="blog-item position-relative overflow-hidden rounded mb-2" >
                                        <img className="img-fluid" src={`https://api.vtalentsolution.com/images/`+item.imageTitle+``} alt="" style={{ height: "400px", width: "350px" }}/>
                                        <a className="blog-overlay text-decoration-none" href={`/news/`+item.id+``}>
                                            <h5 className="text-white mb-3">{item.title}</h5>
                                            <p className="text-primary m-0">{moment(item.created_at).format('MMMM Do YYYY')}</p>
                                        </a>
                                    </div>
                                </div>
                            ))
                        }
                        {
                            catePost.map((item, index)=>(
                                <div className="col-md-8 col-lg-4 col-11 mb-4" key={index}>
                                    <div className="blog-item position-relative overflow-hidden rounded mb-2" >
                                        <img className="img-fluid" src={`https://api.vtalentsolution.com/images/`+item.imageTitle+``} alt="" style={{ height: "400px", width: "350px" }}/>
                                        <a className="blog-overlay text-decoration-none" href={`/news/`+item.id+``}>
                                            <h5 className="text-white mb-3">{item.title}</h5>
                                            <p className="text-primary m-0">{moment(item.created_at).format('MMMM Do YYYY')}</p>
                                        </a>
                                    </div>
                                </div>
                            ))
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Blog End --> */}
        <Footer/>
        <BackTop/>
    </>
    )
}

export default Blogs
